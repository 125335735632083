import React from 'react'

function OrderList() {
  return (
    <>
    <div className="uk-card uk-card-default uk-card-small uk-card-hover">
                <div className="uk-card-header">
                  <div className="uk-grid uk-grid-small">
                    <div className="uk-width-auto"><h4>Sales Orders</h4></div>
                    <div className="uk-width-expand uk-text-right panel-icons">
                      <a href="!#" className="uk-icon-link" title="Close" data-uk-tooltip data-uk-icon="icon: close"></a>
                    </div>
                  </div>
                </div>
                <div className="uk-card-body">
                  <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                        <tr>
                            <th>Table Heading</th>
                            <th>Table Heading</th>
                            <th>Table Heading</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Table Data</td>
                            <td>Table Data</td>
                            <td>Table Data</td>
                        </tr>
                        <tr>
                            <td>Table Data</td>
                            <td>Table Data</td>
                            <td>Table Data</td>
                        </tr>
                        <tr>
                            <td>Table Data</td>
                            <td>Table Data</td>
                            <td>Table Data</td>
                        </tr>
                    </tbody>
                  </table>  
                </div>
              </div>
    </>
  )
}

export default OrderList