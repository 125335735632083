import React from 'react'

const Header = () => {
  return (
    <>
       <header id="top-head" className="uk-position-fixed">
        <div className="uk-container uk-container-expand uk-background-primary">
          <nav className="uk-navbar uk-light" data-uk-navbar="mode:click; duration: 250">
            <div className="uk-navbar-left">
              <div className="uk-navbar-item uk-hidden@m">
                <a className="uk-logo" href="!#"><img className="custom-logo" src="img/dashboard-logo-white.svg" alt=""/></a>
              </div>
              <ul className="uk-navbar-nav uk-visible@m">
                <li><a href="!#">Orders</a></li>
              </ul>
              <div className="uk-navbar-item uk-visible@s">
                <form action="dashboard.html" className="uk-search uk-search-default">
                  <span data-uk-search-icon></span>
                  <input className="uk-search-input search-field" type="search" placeholder="Search" />
                </form>
              </div>
            </div>
            <div className="uk-navbar-right">
              <ul className="uk-navbar-nav">
                <li><a className="uk-navbar-toggle" uk-toggle="target: #offcanvas-nav" data-uk-navbar-toggle-icon href="#offcanvas-nav" title="Offcanvas" data-uk-tooltip></a></li>
              </ul>
            </div>
          </nav>
        </div>
      </header> 
      <div id="offcanvas-nav" data-uk-offcanvas="flip: true; overlay: true">
        <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
          <button className="uk-offcanvas-close uk-close uk-icon" type="button" data-uk-close></button>
          <ul className="uk-nav uk-nav-default">
            <li className="uk-active"><a href="!#">Active</a></li>
            <li className="uk-parent">
              <a href="!#">Parent</a>
              <ul className="uk-nav-sub"> 
                <li><a href="!#">Sub item</a></li>
                <li><a href="!#">Sub item</a></li>
              </ul>
            </li>
            <li className="uk-nav-header">Header</li>
            <li><a href="#js-options"><span className="uk-margin-small-right uk-icon" data-uk-icon="icon: table"></span> Item</a></li>
            <li><a href="!#"><span className="uk-margin-small-right uk-icon" data-uk-icon="icon: thumbnails"></span> Item</a></li>
            <li className="uk-nav-divider"></li>
            <li><a href="!#"><span className="uk-margin-small-right uk-icon" data-uk-icon="icon: trash"></span> Item</a></li>
          </ul>
          <h3>Title</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </div>
    </>
  )
}

export default Header