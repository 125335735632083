import React from 'react'

const Footer = () => {
  return (
    <>
    <footer className="uk-section uk-section-small uk-text-center">
        <hr/>
        <p className="uk-text-small uk-text-center">Copyright 2022 - <a href="https://cheersmile.com">Created by cheersmile</a> | Built with <a href="https://cheersmile.com" title="Visit UIkit 3 site" target="_blank" data-uk-tooltip><span data-uk-icon="uikit"></span></a> </p>
    </footer>  
    </>
  )
}

export default Footer