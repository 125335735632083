import React from 'react'

const leftSideBar = () => {
  return (
    <>
    <aside id="left-col" className="uk-light uk-visible@m">
        <div className="uk-flex uk-flex-middle">
          <h3 className='uk-text-center uk-margin-small uk-width-1-1'>cheersmile</h3>
        </div>
        <div className="left-content-box  content-box-dark">
          <img src="img/avatar.svg" alt="" className="uk-border-circle profile-img" />
          <h4 className="uk-text-center uk-margin-remove-vertical text-light">John Doe</h4>
          
          <div className="uk-position-relative uk-text-center uk-display-block">
              <a href="!#" className="uk-text-small uk-text-muted uk-display-block uk-text-center" data-uk-icon="icon: triangle-down; ratio: 0.7">Admin</a>
              <div className="uk-dropdown user-drop" data-uk-dropdown="mode: click; pos: bottom-center; animation: uk-animation-slide-bottom-small; duration: 150">
                <ul className="uk-nav uk-dropdown-nav uk-text-left">
                  <li><a href="!#"><span data-uk-icon="icon: image"></span> Your Data</a></li>
                  <li className="uk-nav-divider"></li>
                  <li><a href="!#"><span data-uk-icon="icon: sign-out"></span> Sign Out</a></li>
                </ul>
              </div>
          </div>
        </div>
        
        <div className="left-nav-wrap">
          <ul className="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
            <li className="uk-nav-header">ACTIONS</li>
            <li><a href="!#"><span data-uk-icon="icon: comments" className="uk-margin-small-right"></span>Shops</a></li>
            <li><a href="!#"><span data-uk-icon="icon: comments" className="uk-margin-small-right"></span>Orders</a></li>
            <li><a href="!#"><span data-uk-icon="icon: users" className="uk-margin-small-right"></span>Products</a></li>
            <li><a href="!#"><span data-uk-icon="icon: users" className="uk-margin-small-right"></span>Categories</a></li>
            <li><a href="!#"><span data-uk-icon="icon: users" className="uk-margin-small-right"></span>Unit Types</a></li>
            <li className="uk-parent"><a href="!#"><span data-uk-icon="icon: thumbnails" className="uk-margin-small-right"></span>Relationships</a>
              <ul className="uk-nav-sub">
                <li><a title="" href="!#">Customers</a></li>
                <li><a title="" href="!#">Suppliers</a></li>
                <li><a title="" href="!#">Other</a></li>
              </ul>
            </li>
            <li className="uk-parent"><a href="!#"><span data-uk-icon="icon: thumbnails" className="uk-margin-small-right"></span>Reports</a>
              <ul className="uk-nav-sub">
                <li><a title="" href="!#">Sales Report</a></li>
                <li><a title="" href="!#">Reorder Report</a></li>
              </ul>
            </li>
            <li><a href="!#"><span data-uk-icon="icon: comments" className="uk-margin-small-right"></span>About Us</a></li>
            <li><a href="!#"><span data-uk-icon="icon: comments" className="uk-margin-small-right"></span>Contact Us</a></li>
            <li><a href="!#"><span data-uk-icon="icon: comments" className="uk-margin-small-right"></span>Terms And Conditions</a></li>
          </ul>
        </div>
        <div className="bar-bottom">
          <ul className="uk-subnav uk-flex uk-flex-center uk-child-width-1-5" data-uk-grid>
            <li>
              <a href="!#" className="uk-icon-link" data-uk-icon="icon: home" title="Home" data-uk-tooltip></a>
            </li>
            <li>
              <a href="!#" className="uk-icon-link" data-uk-icon="icon: settings" title="Settings" data-uk-tooltip></a>
            </li>
            <li>
              <a href="!#" className="uk-icon-link" data-uk-icon="icon: social"  title="Social" data-uk-tooltip></a>
            </li>
            
            <li>
              <a href="!#" className="uk-icon-link" data-uk-tooltip="Sign out" data-uk-icon="icon: sign-out"></a>
            </li>
          </ul>
        </div>
      </aside>
    </>
  )
}

export default leftSideBar