// import logo from './logo.svg';
// import './App.css'; 

import Header from "./components/common/Header";
import LeftSideBar from "./components/common/LeftSideBar";
import Footer from "./components/common/Footer";
import OrderList from "./components/OrderList";

function App() { 
  return (
    <>
    <div className="App">
      <Header/>
      <LeftSideBar/>
      <div id="content" data-uk-height-viewport="expand: true">
        <div className="uk-container uk-container-expand">
          <div className="uk-grid uk-grid-divider uk-grid-medium uk-child-width-1-2 uk-child-width-1-4@l uk-child-width-1-5@xl" data-uk-grid>
            <div>
              <span className="uk-text-small"><span data-uk-icon="icon:users" className="uk-margin-small-right uk-text-primary"></span>New Users</span>
              <h1 className="uk-heading-primary uk-margin-remove  uk-text-primary">2.134</h1>
              <div className="uk-text-small">
                <span className="uk-text-success" data-uk-icon="icon: triangle-up">15%</span> more than last week.
              </div>
            </div>
            <div>
              
              <span className="uk-text-small"><span data-uk-icon="icon:social" className="uk-margin-small-right uk-text-primary"></span>Social Media</span>
              <h1 className="uk-heading-primary uk-margin-remove uk-text-primary">8.490</h1>
              <div className="uk-text-small">
                <span className="uk-text-warning" data-uk-icon="icon: triangle-down">-15%</span> less than last week.
              </div>
              
            </div>
            <div>
              
              <span className="uk-text-small"><span data-uk-icon="icon:clock" className="uk-margin-small-right uk-text-primary"></span>Traffic hours</span>
              <h1 className="uk-heading-primary uk-margin-remove uk-text-primary">12.00<small className="uk-text-small">PM</small></h1>
              <div className="uk-text-small">
                <span className="uk-text-success" data-uk-icon="icon: triangle-up"> 19%</span> more than last week.
              </div>
              
            </div>
            <div>
              
              <span className="uk-text-small"><span data-uk-icon="icon:search" className="uk-margin-small-right uk-text-primary"></span>Week Search</span>
              <h1 className="uk-heading-primary uk-margin-remove uk-text-primary">9.543</h1>
              <div className="uk-text-small">
                <span className="uk-text-danger" data-uk-icon="icon: triangle-down"> -23%</span> less than last week.
              </div>
              
            </div>
            <div className="uk-visible@xl">
              <span className="uk-text-small"><span data-uk-icon="icon:users" className="uk-margin-small-right uk-text-primary"></span>Lorem ipsum</span>
              <h1 className="uk-heading-primary uk-margin-remove uk-text-primary">5.284</h1>
              <div className="uk-text-small">
                <span className="uk-text-success" data-uk-icon="icon: triangle-up"> 7%</span> more than last week.
              </div>
            </div>
          </div>
          <hr/>
          <div className="uk-grid uk-grid-medium" data-uk-grid uk-sortable="handle: .sortable-icon">
            <div className="uk-width-1-1">
              <OrderList/>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
